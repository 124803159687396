$black: #151515;
$dark: #222;
$light: #e1decf;
$white: #fff;
$highlight: #fa8334;
$info: #4a99e4;
$green: #15ac1f;

$input: $info;
$output: $info;
