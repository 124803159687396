@import "../../style/_colors.scss";

* {
    box-sizing: border-box;
}
body {
    margin: 0;
    background: $light;
    font-family: "Roboto", sans-serif;
}
.app {
    display: flex;
    flex-direction: column;
    height: 100vh;
}
.emulator {
    flex-grow: 1;
    display: flex;
    align-items: stretch;
    overflow-y: hidden;
    > * {
        overflow-y: auto;
        flex-shrink: 0;
    }
}
@media screen and (max-width: 550px) {
    .emulator {
        flex-direction: column;
    }

    .app {
        height: auto;
        min-height: 100vh;
    }
}

.faint {
    opacity: 0.66;
}
.highlight-input {
    border-color: $input !important;
    border-style: dashed !important;
    background: rgba($input, 0.25) !important;
}
.highlight-output {
    border-color: $output !important;
    border-style: solid !important;
    background: rgba($output, 0.25) !important;
}

input[type="text"] {
    border-radius: 3px;
    padding: 4px 8px;
    margin: 2px;
    font-family: "Roboto", sans-serif;
    font-size: 16px;

    &:focus,
    &:active,
    &.focused {
        border-color: $highlight;
        outline: none;
    }
}

input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 24px;
    background: transparent;
    margin: 0;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-top: -3px;
        box-shadow: 0 0 0 3px $black;
        background: $light;
        border: none;
    }
    &::-moz-range-thumb {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-top: -3px;
        box-shadow: 0 0 0 3px $black;
        background: $light;
        border: none;
    }
    &::-ms-thumb {
        width: 8px;
        height: 8px;
        border-radius: 8px;
        margin-top: -3px;
        box-shadow: 0 0 0 3px $black;
        background: $light;
        border: none;
    }

    &::-webkit-slider-runnable-track,
    &::-moz-range-track {
        height: 2px;
        border-radius: 2px;
        background: mix($light, $dark, 25%);
    }
    &::-ms-track {
        width: 100%;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &:focus {
        outline: none;

        &::-webkit-slider-thumb {
            background: $highlight;
        }
        &::-moz-range-thumb {
            background: $highlight;
        }
        &::-ms-thumb {
            background: $highlight;
        }
    }
}

.swal2-modal {
    width: 400px !important;
    min-height: 0px !important;

    .swal2-icon {
        width: 40px;
        height: 40px;
        margin: 10px auto;

        &.swal2-warning {
            line-height: 40px;
            font-size: 30px;
        }
    }

    .swal2-confirm {
        margin-top: 0;
    }
}
