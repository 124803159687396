@import "../../style/_colors.scss";
@import url("https://cdn.rawgit.com/dreampulse/computer-modern-web-font/master/font/Serif/cmun-serif.css");

.help-container {
    overflow-x: hidden;
    width: 70ch;
    max-width: 50vw;
    min-width: 30ch;

    .emulator > & {
        flex-shrink: 1;
    }
}
.help {
    width: 100%;
    padding: 16px 32px;
    background: $white;
    font-family: "Computer Modern Serif", serif;
    font-size: 18px;
    box-shadow: -2px 0 16px rgba($dark, 0.1);
    border-left: 1px solid mix($dark, $light, 25%);

    animation: slide-in 0.2s ease-out;

    h1,
    h2,
    h3 {
        text-align: center;
    }

    table {
        max-width: 60ch;
    }
    td:nth-child(-n + 2),
    em {
        text-align: right;
        font-style: normal;
        font-weight: bold;
    }
    td,
    th {
        padding: 4px 8px;
        vertical-align: top;
    }
    th:last-child {
        text-align: left;
    }

    .counter-arrow {
        display: inline-block;
        width: 0;
        height: 0;
        left: 0;
        border: 12px solid transparent;
        border-right: none;
        border-left-color: $highlight;
        vertical-align: middle;
        margin: 0 4px;
    }
    .highlight-example {
        display: inline-block;
        height: 1.2em;
        width: 2em;
        border-radius: 3px;
        vertical-align: middle;
        margin: 0 4px;
    }
}

@media (max-width: 912px) {
    .help-container,
    .toolbar .btn-help {
        display: none;
    }
}

@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0%);
    }
}
