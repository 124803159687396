@import "https://cdn.rawgit.com/dreampulse/computer-modern-web-font/master/font/Serif/cmun-serif.css";
.swal2-popup.swal2-toast {
  background: #fff;
  flex-direction: row;
  align-items: center;
  width: auto;
  padding: .625em;
  overflow-y: hidden;
  box-shadow: 0 0 .625em #d9d9d9;
}

.swal2-popup.swal2-toast .swal2-header {
  flex-direction: row;
  padding: 0;
}

.swal2-popup.swal2-toast .swal2-title {
  flex-grow: 1;
  justify-content: flex-start;
  margin: 0 .6em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-footer {
  margin: .5em 0 0;
  padding: .5em 0 0;
  font-size: .8em;
}

.swal2-popup.swal2-toast .swal2-close {
  width: .8em;
  height: .8em;
  line-height: .8;
  position: static;
}

.swal2-popup.swal2-toast .swal2-content {
  justify-content: flex-start;
  padding: 0;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-icon {
  width: 2em;
  min-width: 2em;
  height: 2em;
  margin: 0;
}

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
  align-items: center;
  font-size: 1.8em;
  font-weight: bold;
  display: flex;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    font-size: .25em;
  }
}

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  width: 1.375em;
  top: .875em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: .3125em;
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: .3125em;
}

.swal2-popup.swal2-toast .swal2-actions {
  width: auto;
  height: auto;
  margin: 0 .3125em;
  padding: 0;
  flex-basis: auto !important;
}

.swal2-popup.swal2-toast .swal2-styled {
  margin: 0 .3125em;
  padding: .3125em .625em;
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-styled:focus {
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #32649666;
}

.swal2-popup.swal2-toast .swal2-success {
  border-color: #a5dc86;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
  border-radius: 50%;
  width: 1.6em;
  height: 3em;
  position: absolute;
  transform: rotate(45deg);
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="left"] {
  transform-origin: 2em 2em;
  border-radius: 4em 0 0 4em;
  top: -.8em;
  left: -.5em;
  transform: rotate(-45deg);
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="right"] {
  transform-origin: 0 1.5em;
  border-radius: 0 4em 4em 0;
  top: -.25em;
  left: .9375em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
  width: 2em;
  height: 2em;
}

.swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
  width: .4375em;
  height: 2.6875em;
  top: 0;
  left: .4375em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"] {
  height: .3125em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: .75em;
  top: 1.125em;
  left: .1875em;
}

.swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 1.375em;
  top: .9375em;
  right: .1875em;
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: .75s swal2-toast-animate-success-line-tip;
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: .75s swal2-toast-animate-success-line-long;
}

.swal2-popup.swal2-toast.swal2-show {
  animation: .5s swal2-toast-show;
}

.swal2-popup.swal2-toast.swal2-hide {
  animation: .1s forwards swal2-toast-hide;
}

.swal2-container {
  z-index: 1060;
  -webkit-overflow-scrolling: touch;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .625em;
  transition: background-color .1s;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-x: hidden;
}

.swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
  background: #0006;
}

.swal2-container.swal2-backdrop-hide {
  background: none !important;
}

.swal2-container.swal2-top {
  align-items: flex-start;
}

.swal2-container.swal2-top-start, .swal2-container.swal2-top-left {
  justify-content: flex-start;
  align-items: flex-start;
}

.swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
  justify-content: flex-end;
  align-items: flex-start;
}

.swal2-container.swal2-center {
  align-items: center;
}

.swal2-container.swal2-center-start, .swal2-container.swal2-center-left {
  justify-content: flex-start;
  align-items: center;
}

.swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
  justify-content: flex-end;
  align-items: center;
}

.swal2-container.swal2-bottom {
  align-items: flex-end;
}

.swal2-container.swal2-bottom-start, .swal2-container.swal2-bottom-left {
  justify-content: flex-start;
  align-items: flex-end;
}

.swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
  justify-content: flex-end;
  align-items: flex-end;
}

.swal2-container.swal2-bottom > :first-child, .swal2-container.swal2-bottom-start > :first-child, .swal2-container.swal2-bottom-left > :first-child, .swal2-container.swal2-bottom-end > :first-child, .swal2-container.swal2-bottom-right > :first-child {
  margin-top: auto;
}

.swal2-container.swal2-grow-fullscreen > .swal2-modal {
  flex: 1;
  justify-content: center;
  align-self: stretch;
  display: flex !important;
}

.swal2-container.swal2-grow-row > .swal2-modal {
  flex: 1;
  place-content: center;
  display: flex !important;
}

.swal2-container.swal2-grow-column {
  flex-direction: column;
  flex: 1;
}

.swal2-container.swal2-grow-column.swal2-top, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-bottom {
  align-items: center;
}

.swal2-container.swal2-grow-column.swal2-top-start, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-bottom-left {
  align-items: flex-start;
}

.swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-top-right, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-bottom-right {
  align-items: flex-end;
}

.swal2-container.swal2-grow-column > .swal2-modal {
  flex: 1;
  place-content: center;
  display: flex !important;
}

.swal2-container.swal2-no-transition {
  transition: none !important;
}

.swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right):not(.swal2-grow-fullscreen) > .swal2-modal {
  margin: auto;
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-container .swal2-modal {
    margin: 0 !important;
  }
}

.swal2-popup {
  box-sizing: border-box;
  background: #fff;
  border: none;
  border-radius: .3125em;
  flex-direction: column;
  justify-content: center;
  width: 32em;
  max-width: 100%;
  padding: 1.25em;
  font-family: inherit;
  font-size: 1rem;
  display: none;
  position: relative;
}

.swal2-popup:focus {
  outline: none;
}

.swal2-popup.swal2-loading {
  overflow-y: hidden;
}

.swal2-header {
  flex-direction: column;
  align-items: center;
  padding: 0 1.8em;
  display: flex;
}

.swal2-title {
  color: #595959;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  max-width: 100%;
  margin: 0 0 .4em;
  padding: 0;
  font-size: 1.875em;
  font-weight: 600;
  position: relative;
}

.swal2-actions {
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1.25em auto 0;
  padding: 0 1.6em;
  display: flex;
}

.swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
  opacity: .4;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover {
  background-image: linear-gradient(#0000001a, #0000001a);
}

.swal2-actions:not(.swal2-loading) .swal2-styled:active {
  background-image: linear-gradient(#0003, #0003);
}

.swal2-loader {
  border: .25em solid #2778c4;
  border-color: #2778c4 #0000;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  width: 2.2em;
  height: 2.2em;
  margin: 0 1.875em;
  animation: 1.5s linear infinite swal2-rotate-loading;
  display: none;
}

.swal2-styled {
  box-shadow: none;
  margin: .3125em;
  padding: .625em 2em;
  font-weight: 500;
}

.swal2-styled:not([disabled]) {
  cursor: pointer;
}

.swal2-styled.swal2-confirm {
  background: initial;
  color: #fff;
  background-color: #2778c4;
  border: 0;
  border-radius: .25em;
  font-size: 1.0625em;
}

.swal2-styled.swal2-deny {
  background: initial;
  color: #fff;
  background-color: #d14529;
  border: 0;
  border-radius: .25em;
  font-size: 1.0625em;
}

.swal2-styled.swal2-cancel {
  background: initial;
  color: #fff;
  background-color: #757575;
  border: 0;
  border-radius: .25em;
  font-size: 1.0625em;
}

.swal2-styled:focus {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #32649666;
}

.swal2-styled::-moz-focus-inner {
  border: 0;
}

.swal2-footer {
  color: #545454;
  border-top: 1px solid #eee;
  justify-content: center;
  margin: 1.25em 0 0;
  padding: 1em 0 0;
  font-size: 1em;
}

.swal2-timer-progress-bar-container {
  border-bottom-right-radius: .3125em;
  border-bottom-left-radius: .3125em;
  height: .25em;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.swal2-timer-progress-bar {
  background: #0003;
  width: 100%;
  height: .25em;
}

.swal2-image {
  max-width: 100%;
  margin: 1.25em auto;
}

.swal2-close {
  z-index: 2;
  color: #ccc;
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  width: 1.2em;
  height: 1.2em;
  padding: 0;
  font-family: serif;
  font-size: 2.5em;
  line-height: 1.2;
  transition: color .1s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

.swal2-close:hover {
  color: #f27474;
  background: none;
  transform: none;
}

.swal2-close::-moz-focus-inner {
  border: 0;
}

.swal2-content {
  z-index: 1;
  color: #545454;
  text-align: center;
  word-wrap: break-word;
  justify-content: center;
  margin: 0;
  padding: 0 1.6em;
  font-size: 1.125em;
  font-weight: normal;
  line-height: normal;
}

.swal2-input, .swal2-file, .swal2-textarea, .swal2-select, .swal2-radio, .swal2-checkbox {
  margin: 1em auto;
}

.swal2-input, .swal2-file, .swal2-textarea {
  box-sizing: border-box;
  background: inherit;
  color: inherit;
  border: 1px solid #d9d9d9;
  border-radius: .1875em;
  width: 100%;
  font-size: 1.125em;
  transition: border-color .3s, box-shadow .3s;
  box-shadow: inset 0 1px 1px #0000000f;
}

.swal2-input.swal2-inputerror, .swal2-file.swal2-inputerror, .swal2-textarea.swal2-inputerror {
  border-color: #f27474 !important;
  box-shadow: 0 0 2px #f27474 !important;
}

.swal2-input:focus, .swal2-file:focus, .swal2-textarea:focus {
  border: 1px solid #b4dbed;
  outline: none;
  box-shadow: 0 0 3px #c4e6f5;
}

.swal2-input::-moz-placeholder {
  color: #ccc;
}

.swal2-file::-moz-placeholder {
  color: #ccc;
}

.swal2-textarea::-moz-placeholder {
  color: #ccc;
}

:is(.swal2-input:-ms-input-placeholder, .swal2-file:-ms-input-placeholder, .swal2-textarea:-ms-input-placeholder), .swal2-input::placeholder, .swal2-file::placeholder, .swal2-textarea::placeholder {
  color: #ccc;
}

.swal2-range {
  background: #fff;
  margin: 1em auto;
}

.swal2-range input {
  width: 80%;
}

.swal2-range output {
  color: inherit;
  text-align: center;
  width: 20%;
  font-weight: 600;
}

.swal2-range input, .swal2-range output {
  height: 2.625em;
  padding: 0;
  font-size: 1.125em;
  line-height: 2.625em;
}

.swal2-input {
  height: 2.625em;
  padding: 0 .75em;
}

.swal2-input[type="number"] {
  max-width: 10em;
}

.swal2-file {
  background: inherit;
  font-size: 1.125em;
}

.swal2-textarea {
  height: 6.75em;
  padding: .75em;
}

.swal2-select {
  background: inherit;
  color: inherit;
  min-width: 50%;
  max-width: 100%;
  padding: .375em .625em;
  font-size: 1.125em;
}

.swal2-radio, .swal2-checkbox {
  color: inherit;
  background: #fff;
  justify-content: center;
  align-items: center;
}

.swal2-radio label, .swal2-checkbox label {
  margin: 0 .6em;
  font-size: 1.125em;
}

.swal2-radio input, .swal2-checkbox input {
  margin: 0 .4em;
}

.swal2-input-label {
  justify-content: center;
  margin: 1em auto;
  display: flex;
}

.swal2-validation-message {
  color: #666;
  background: #f0f0f0;
  justify-content: center;
  align-items: center;
  margin: 0 -2.7em;
  padding: .625em;
  font-size: 1em;
  font-weight: 300;
  display: none;
  overflow: hidden;
}

.swal2-validation-message:before {
  content: "!";
  color: #fff;
  text-align: center;
  background-color: #f27474;
  border-radius: 50%;
  width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  margin: 0 .625em;
  font-weight: 600;
  line-height: 1.5em;
  display: inline-block;
}

.swal2-icon {
  box-sizing: content-box;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  border: .25em solid #0000;
  border-radius: 50%;
  justify-content: center;
  width: 5em;
  height: 5em;
  margin: 1.25em auto 1.875em;
  font-family: inherit;
  line-height: 5em;
  position: relative;
}

.swal2-icon .swal2-icon-content {
  align-items: center;
  font-size: 3.75em;
  display: flex;
}

.swal2-icon.swal2-error {
  color: #f27474;
  border-color: #f27474;
}

.swal2-icon.swal2-error .swal2-x-mark {
  flex-grow: 1;
  position: relative;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
  background-color: #f27474;
  border-radius: .125em;
  width: 2.9375em;
  height: .3125em;
  display: block;
  position: absolute;
  top: 2.3125em;
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
  left: 1.0625em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
  right: 1em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-error.swal2-icon-show {
  animation: .5s swal2-animate-error-icon;
}

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
  animation: .5s swal2-animate-error-x-mark;
}

.swal2-icon.swal2-warning {
  color: #f8bb86;
  border-color: #facea8;
}

.swal2-icon.swal2-info {
  color: #3fc3ee;
  border-color: #9de0f6;
}

.swal2-icon.swal2-question {
  color: #87adbd;
  border-color: #c9dae1;
}

.swal2-icon.swal2-success {
  color: #a5dc86;
  border-color: #a5dc86;
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"] {
  border-radius: 50%;
  width: 3.75em;
  height: 7.5em;
  position: absolute;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"][class$="left"] {
  transform-origin: 3.75em 3.75em;
  border-radius: 7.5em 0 0 7.5em;
  top: -.4375em;
  left: -2.0635em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-circular-line"][class$="right"] {
  transform-origin: 0 3.75em;
  border-radius: 0 7.5em 7.5em 0;
  top: -.6875em;
  left: 1.875em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success .swal2-success-ring {
  z-index: 2;
  box-sizing: content-box;
  border: .25em solid #a5dc864d;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  position: absolute;
  top: -.25em;
  left: -.25em;
}

.swal2-icon.swal2-success .swal2-success-fix {
  z-index: 1;
  width: .4375em;
  height: 5.625em;
  position: absolute;
  top: .5em;
  left: 1.625em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  z-index: 2;
  background-color: #a5dc86;
  border-radius: .125em;
  height: .3125em;
  display: block;
  position: absolute;
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="tip"] {
  width: 1.5625em;
  top: 2.875em;
  left: .8125em;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success [class^="swal2-success-line"][class$="long"] {
  width: 2.9375em;
  top: 2.375em;
  right: .5em;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: .75s swal2-animate-success-line-tip;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: .75s swal2-animate-success-line-long;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  animation: 4.25s ease-in swal2-rotate-success-circular-line;
}

.swal2-progress-steps {
  background: inherit;
  flex-wrap: wrap;
  align-items: center;
  max-width: 100%;
  margin: 0 0 1.25em;
  padding: 0;
  font-weight: 600;
}

.swal2-progress-steps li {
  display: inline-block;
  position: relative;
}

.swal2-progress-steps .swal2-progress-step {
  z-index: 20;
  color: #fff;
  text-align: center;
  background: #2778c4;
  border-radius: 2em;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  line-height: 2em;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step {
  background: #2778c4;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step {
  color: #fff;
  background: #add8e6;
}

.swal2-progress-steps .swal2-progress-step.swal2-active-progress-step ~ .swal2-progress-step-line {
  background: #add8e6;
}

.swal2-progress-steps .swal2-progress-step-line {
  z-index: 10;
  background: #2778c4;
  flex-shrink: 0;
  width: 2.5em;
  height: .4em;
  margin: 0 -1px;
}

[class^="swal2"] {
  -webkit-tap-highlight-color: transparent;
}

.swal2-show {
  animation: .3s swal2-show;
}

.swal2-hide {
  animation: .15s forwards swal2-hide;
}

.swal2-noanimation {
  transition: none;
}

.swal2-scrollbar-measure {
  width: 50px;
  height: 50px;
  position: absolute;
  top: -9999px;
  overflow: scroll;
}

.swal2-rtl .swal2-close {
  left: 0;
  right: auto;
}

.swal2-rtl .swal2-timer-progress-bar {
  left: auto;
  right: 0;
}

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }

  .swal2-range output {
    display: none;
  }
}

@media (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }

  .swal2-range output {
    display: none;
  }
}

@-moz-document url-prefix() {
  .swal2-close:focus {
    outline: 2px solid #32649666;
  }
}

@keyframes swal2-toast-show {
  0% {
    transform: translateY(-.625em)rotateZ(2deg);
  }

  33% {
    transform: translateY(0)rotateZ(-2deg);
  }

  66% {
    transform: translateY(.3125em)rotateZ(2deg);
  }

  100% {
    transform: translateY(0)rotateZ(0);
  }
}

@keyframes swal2-toast-hide {
  100% {
    opacity: 0;
    transform: rotateZ(1deg);
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    width: 0;
    top: .5625em;
    left: .0625em;
  }

  54% {
    width: 0;
    top: .125em;
    left: .125em;
  }

  70% {
    width: 1.625em;
    top: .625em;
    left: -.25em;
  }

  84% {
    width: .5em;
    top: 1.0625em;
    left: .75em;
  }

  100% {
    width: .75em;
    top: 1.125em;
    left: .1875em;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    width: 0;
    top: 1.625em;
    right: 1.375em;
  }

  65% {
    width: 0;
    top: 1.25em;
    right: .9375em;
  }

  84% {
    width: 1.125em;
    top: .9375em;
    right: 0;
  }

  100% {
    width: 1.375em;
    top: .9375em;
    right: .1875em;
  }
}

@keyframes swal2-show {
  0% {
    transform: scale(.7);
  }

  45% {
    transform: scale(1.05);
  }

  80% {
    transform: scale(.95);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes swal2-hide {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.5);
  }
}

@keyframes swal2-animate-success-line-tip {
  0% {
    width: 0;
    top: 1.1875em;
    left: .0625em;
  }

  54% {
    width: 0;
    top: 1.0625em;
    left: .125em;
  }

  70% {
    width: 3.125em;
    top: 2.1875em;
    left: -.375em;
  }

  84% {
    width: 1.0625em;
    top: 3em;
    left: 1.3125em;
  }

  100% {
    width: 1.5625em;
    top: 2.8125em;
    left: .8125em;
  }
}

@keyframes swal2-animate-success-line-long {
  0% {
    width: 0;
    top: 3.375em;
    right: 2.875em;
  }

  65% {
    width: 0;
    top: 3.375em;
    right: 2.875em;
  }

  84% {
    width: 3.4375em;
    top: 2.1875em;
    right: 0;
  }

  100% {
    width: 2.9375em;
    top: 2.375em;
    right: .5em;
  }
}

@keyframes swal2-rotate-success-circular-line {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes swal2-animate-error-x-mark {
  0% {
    opacity: 0;
    margin-top: 1.625em;
    transform: scale(.4);
  }

  50% {
    opacity: 0;
    margin-top: 1.625em;
    transform: scale(.4);
  }

  80% {
    margin-top: -.375em;
    transform: scale(1.15);
  }

  100% {
    opacity: 1;
    margin-top: 0;
    transform: scale(1);
  }
}

@keyframes swal2-animate-error-icon {
  0% {
    opacity: 0;
    transform: rotateX(100deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

@keyframes swal2-rotate-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
  overflow: hidden;
}

body.swal2-height-auto {
  height: auto !important;
}

body.swal2-no-backdrop .swal2-container {
  max-width: calc(100% - 1.25em);
  inset: auto;
  background-color: #0000 !important;
}

body.swal2-no-backdrop .swal2-container > .swal2-modal {
  box-shadow: 0 0 10px #0006;
}

body.swal2-no-backdrop .swal2-container.swal2-top {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-top-start, body.swal2-no-backdrop .swal2-container.swal2-top-left {
  top: 0;
  left: 0;
}

body.swal2-no-backdrop .swal2-container.swal2-top-end, body.swal2-no-backdrop .swal2-container.swal2-top-right {
  top: 0;
  right: 0;
}

body.swal2-no-backdrop .swal2-container.swal2-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

body.swal2-no-backdrop .swal2-container.swal2-center-start, body.swal2-no-backdrop .swal2-container.swal2-center-left {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-center-end, body.swal2-no-backdrop .swal2-container.swal2-center-right {
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-bottom {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

body.swal2-no-backdrop .swal2-container.swal2-bottom-start, body.swal2-no-backdrop .swal2-container.swal2-bottom-left {
  bottom: 0;
  left: 0;
}

body.swal2-no-backdrop .swal2-container.swal2-bottom-end, body.swal2-no-backdrop .swal2-container.swal2-bottom-right {
  bottom: 0;
  right: 0;
}

@media print {
  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: scroll !important;
  }

  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) > [aria-hidden="true"] {
    display: none;
  }

  body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) .swal2-container {
    position: static !important;
  }
}

body.swal2-toast-shown .swal2-container {
  background-color: #0000;
}

body.swal2-toast-shown .swal2-container.swal2-top {
  inset: 0 auto auto 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-top-end, body.swal2-toast-shown .swal2-container.swal2-top-right {
  inset: 0 0 auto auto;
}

body.swal2-toast-shown .swal2-container.swal2-top-start, body.swal2-toast-shown .swal2-container.swal2-top-left {
  inset: 0 auto auto 0;
}

body.swal2-toast-shown .swal2-container.swal2-center-start, body.swal2-toast-shown .swal2-container.swal2-center-left {
  inset: 50% auto auto 0;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-center {
  inset: 50% auto auto 50%;
  transform: translate(-50%, -50%);
}

body.swal2-toast-shown .swal2-container.swal2-center-end, body.swal2-toast-shown .swal2-container.swal2-center-right {
  inset: 50% 0 auto auto;
  transform: translateY(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-start, body.swal2-toast-shown .swal2-container.swal2-bottom-left {
  inset: auto auto 0 0;
}

body.swal2-toast-shown .swal2-container.swal2-bottom {
  inset: auto auto 0 50%;
  transform: translateX(-50%);
}

body.swal2-toast-shown .swal2-container.swal2-bottom-end, body.swal2-toast-shown .swal2-container.swal2-bottom-right {
  inset: auto 0 0 auto;
}

body.swal2-toast-column .swal2-toast {
  flex-direction: column;
  align-items: stretch;
}

body.swal2-toast-column .swal2-toast .swal2-actions {
  flex: 1;
  align-self: stretch;
  height: 2.2em;
  margin-top: .3125em;
}

body.swal2-toast-column .swal2-toast .swal2-loading {
  justify-content: center;
}

body.swal2-toast-column .swal2-toast .swal2-input {
  height: 2em;
  margin: .3125em auto;
  font-size: 1em;
}

body.swal2-toast-column .swal2-toast .swal2-validation-message {
  font-size: 1em;
}

.toolbar {
  color: #e1decf;
  background: #151515;
  flex-shrink: 0;
  align-items: stretch;
  padding: 0 8px;
  font-size: 14px;
  display: flex;
}

.toolbar button, .toolbar a {
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  background: none;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 64px;
  padding: 8px 16px;
  display: flex;
}

.toolbar button span, .toolbar a span {
  display: block;
}

.toolbar button:hover, .toolbar button:focus, .toolbar a:hover, .toolbar a:focus {
  background: #e1decf1a;
  outline: none;
}

.toolbar button:active > *, .toolbar a:active > * {
  transition: transform .1s;
  transform: translateY(1px);
}

.toolbar .play-speed {
  text-align: center;
  padding: 8px;
}

.toolbar .play-speed input {
  display: block;
}

.toolbar .separator {
  margin-right: 16px;
  position: relative;
}

.toolbar .separator:after {
  content: "";
  pointer-events: none;
  background: #e1decf;
  width: 1px;
  position: absolute;
  top: 12px;
  bottom: 12px;
  left: 100%;
  transform: translateX(8px);
}

.toolbar .filler {
  flex-grow: 1;
}

.toolbar .filler, .toolbar .filler span {
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  font-weight: 700;
  display: flex;
}

.toolbar .filler > svg, .toolbar .filler span > svg {
  margin-right: 8px;
}

.toolbar .filler span.error {
  color: #fa8334;
}

.toolbar .filler span.loader {
  color: #fff;
}

.toolbar .filler span.loader svg {
  animation: .75s cubic-bezier(.42, .2, .58, .8) infinite loader;
}

@media (width <= 720px) {
  .toolbar .btn-github, .toolbar .filler {
    display: none;
  }
}

@media (width <= 512px) {
  .toolbar {
    flex-wrap: wrap;
  }
}

@keyframes loader {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.cpu {
  color: #e1decf;
  background: #222;
  padding: 8px 0;
}

.cpu .counter, .cpu .register {
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  display: flex;
}

.cpu .counter {
  margin-bottom: 24px;
  padding: 8px 16px;
  position: relative;
}

.cpu .counter:after {
  content: "";
  border-bottom: 1px solid #e1decf;
  position: absolute;
  top: 100%;
  left: 16px;
  right: 16px;
  transform: translate(8px);
}

.cpu label {
  margin-right: 8px;
}

.cpu input[type="text"] {
  -webkit-appearance: none;
  color: #fff;
  box-sizing: content-box;
  background: none;
  border: none;
  border-radius: 3px;
  width: 2ch;
  margin: 2px;
  padding: 4px 8px;
  font-family: Roboto Mono, monospace;
  font-size: 16px;
  display: inline-block;
}

.cpu input[type="text"]:focus {
  border: 2px solid #fa8334;
  outline: none;
  margin: 0;
}

.cpu input[type="text"]:focus + label {
  color: #fa8334;
}

.cpu input[type="text"].highlight-output, .cpu input[type="text"].highlight-input {
  border-width: 2px;
  margin: 0;
}

.ram {
  flex-grow: 1;
  padding: 14px 0;
}

.ram .row {
  align-items: center;
  padding: 2px 16px;
  display: flex;
}

.ram .row.is-active {
  position: relative;
}

.ram .row.is-active:before {
  content: "";
  border: 12px solid #0000;
  border-left-color: #fa8334;
  border-right: none;
  width: 0;
  height: 0;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-12px);
}

.ram .index {
  opacity: .5;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
}

.ram .comment {
  text-overflow: ellipsis;
  background: none;
  border: none;
  border-bottom: 2px solid #cecbbe;
  border-radius: 0;
  flex-grow: 1;
  margin-left: 16px;
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
}

.cell.cell {
  white-space: nowrap;
  margin: 0 8px;
}

.cell.cell input {
  color: #151515;
  box-sizing: content-box;
  background: #fff;
  border: 2px solid #b1afa4;
  width: 2ch;
  margin: 0;
  font-family: Roboto Mono, monoscape;
}

.cell.cell input:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none !important;
}

.cell.cell input:last-child {
  border-left-width: 1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.cell.cell input.highlight-input, .cell.cell input.highlight-output {
  background: #c0dbf6 !important;
}

.help-container {
  width: 70ch;
  min-width: 30ch;
  max-width: 50vw;
  overflow-x: hidden;
}

.emulator > .help-container {
  flex-shrink: 1;
}

.help {
  background: #fff;
  border-left: 1px solid #b1afa4;
  width: 100%;
  padding: 16px 32px;
  font-family: Computer Modern Serif, serif;
  font-size: 18px;
  animation: .2s ease-out slide-in;
  box-shadow: -2px 0 16px #2222221a;
}

.help h1, .help h2, .help h3 {
  text-align: center;
}

.help table {
  max-width: 60ch;
}

.help td:nth-child(-n+2), .help em {
  text-align: right;
  font-style: normal;
  font-weight: bold;
}

.help td, .help th {
  vertical-align: top;
  padding: 4px 8px;
}

.help th:last-child {
  text-align: left;
}

.help .counter-arrow {
  vertical-align: middle;
  border: 12px solid #0000;
  border-left-color: #fa8334;
  border-right: none;
  width: 0;
  height: 0;
  margin: 0 4px;
  display: inline-block;
  left: 0;
}

.help .highlight-example {
  vertical-align: middle;
  border-radius: 3px;
  width: 2em;
  height: 1.2em;
  margin: 0 4px;
  display: inline-block;
}

@media (width <= 912px) {
  .help-container, .toolbar .btn-help {
    display: none;
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0%);
  }
}

.modal {
  z-index: 999;
  color: #e1decf;
  background: #222;
  justify-content: center;
  align-items: center;
  max-height: 100vh;
  display: flex;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.modal .container {
  flex-direction: column;
  display: flex;
}

.modal .close {
  -webkit-appearance: none;
  color: #e1decf;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  align-self: flex-end;
}

.modal .close svg {
  width: 32px;
  height: 32px;
}

.modal .close:active, .modal .close:focus {
  color: #fa8334;
}

.modal .submit {
  color: #222;
  cursor: pointer;
  background: #fa8334;
  border: 2px solid #c46b30;
  border-radius: 0 0 3px 3px;
  padding: 8px 16px;
}

.modal textarea {
  color: #222;
  resize: none;
  background: #e1decf;
  border: 2px solid #b1afa4;
  border-bottom-width: 0;
  border-radius: 3px 3px 0 0;
  outline: none;
  width: 80ch;
  height: 10em;
  max-height: 50vh;
  padding: 1em;
  font-family: Roboto Mono, monospace;
  box-shadow: 0 2px 8px #0003;
}

.modal textarea:active, .modal textarea:focus {
  border-color: #fa8334;
}

.modal textarea[readonly] {
  color: #e1decf;
  background: none;
  border-color: #fa8334;
  border-bottom-width: 2px;
  border-radius: 3px;
}

* {
  box-sizing: border-box;
}

body {
  background: #e1decf;
  margin: 0;
  font-family: Roboto, sans-serif;
}

.app {
  flex-direction: column;
  height: 100vh;
  display: flex;
}

.emulator {
  flex-grow: 1;
  align-items: stretch;
  display: flex;
  overflow-y: hidden;
}

.emulator > * {
  flex-shrink: 0;
  overflow-y: auto;
}

@media screen and (width <= 550px) {
  .emulator {
    flex-direction: column;
  }

  .app {
    height: auto;
    min-height: 100vh;
  }
}

.faint {
  opacity: .66;
}

.highlight-input {
  background: #4a99e440 !important;
  border-style: dashed !important;
  border-color: #4a99e4 !important;
}

.highlight-output {
  background: #4a99e440 !important;
  border-style: solid !important;
  border-color: #4a99e4 !important;
}

input[type="text"] {
  border-radius: 3px;
  margin: 2px;
  padding: 4px 8px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
}

input[type="text"]:focus, input[type="text"]:active, input[type="text"].focused {
  border-color: #fa8334;
  outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  background: none;
  width: 100%;
  height: 24px;
  margin: 0;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #e1decf;
  border: none;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin-top: -3px;
  box-shadow: 0 0 0 3px #151515;
}

input[type="range"]::-moz-range-thumb {
  background: #e1decf;
  border: none;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin-top: -3px;
  box-shadow: 0 0 0 3px #151515;
}

input[type="range"]::-ms-thumb {
  background: #e1decf;
  border: none;
  border-radius: 8px;
  width: 8px;
  height: 8px;
  margin-top: -3px;
  box-shadow: 0 0 0 3px #151515;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #52514d;
  border-radius: 2px;
  height: 2px;
}

input[type="range"]::-moz-range-track {
  background: #52514d;
  border-radius: 2px;
  height: 2px;
}

input[type="range"]::-ms-track {
  cursor: pointer;
  color: #0000;
  background: none;
  border-color: #0000;
  width: 100%;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]:focus::-webkit-slider-thumb {
  background: #fa8334;
}

input[type="range"]:focus::-moz-range-thumb {
  background: #fa8334;
}

input[type="range"]:focus::-ms-thumb {
  background: #fa8334;
}

.swal2-modal {
  width: 400px !important;
  min-height: 0 !important;
}

.swal2-modal .swal2-icon {
  width: 40px;
  height: 40px;
  margin: 10px auto;
}

.swal2-modal .swal2-icon.swal2-warning {
  font-size: 30px;
  line-height: 40px;
}

.swal2-modal .swal2-confirm {
  margin-top: 0;
}

/*# sourceMappingURL=index.7a120158.css.map */
