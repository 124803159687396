@import "../../style/_colors.scss";

.cell.cell {
    margin: 0 8px;
    white-space: nowrap;
    input {
        color: $black;
        background: #fff;
        border: 2px solid mix($dark, $light, 25%);
        margin: 0;

        width: 2ch;
        box-sizing: content-box;
        font-family: "Roboto Mono", monoscape;

        &:first-child {
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
            border-right: none !important;
        }
        &:last-child {
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-left-width: 1px;
        }

        &.highlight-input {
            background: mix($input, $white, 35%) !important;
        }
        &.highlight-output {
            background: mix($output, $white, 35%) !important;
        }
    }
}
